import type { Item, ItemDescription } from '~/types/artcurial/artcurial-domain';

export type UseLotDescriptionResultUndefined = ComputedRef<
  ItemDescription | undefined
>;

export type UseLotDescriptionResult = ComputedRef<ItemDescription>;

export function useLotDescription(lot: Ref<Item>): UseLotDescriptionResult;
export function useLotDescription(
  lot: Ref<Item | undefined>
): UseLotDescriptionResultUndefined;

/**
 * Get the lot description depending on the locale
 * @param lot The lot to get the description from
 * @returns The lot description
 */
export function useLotDescription(
  lot: Ref<Item | undefined>
): UseLotDescriptionResultUndefined {
  const { locale } = useI18n();

  return computed(() => {
    if (!lot.value) return undefined;
    if (locale.value === 'en') {
      if (lot.value.descriptions?.ENGLISH)
        return lot.value.descriptions.ENGLISH;
    }

    // The lot description has a French version by default
    return lot.value.descriptions.FRENCH!;
  });
}
